import React, { useCallback } from 'react'
import { Typography, Box, CardMedia, Link } from '@mui/material'
import QRCode from 'react-qr-code'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'redux/app.hooks'
import { closeModal } from 'redux/reducers/ui.reducer'
import { APP_STORE_LINK, FIREBASE_DEEP_LINK, GOOGLE_PLAY_LINK } from 'constants/configuration.constants'

import useStyles from './styles'

const SoonNotEnabled = () => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  const close = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} sx={{ fontWeight: 700, color: 'grey.500' }}>
        {t('info:download_install_app')}
      </Typography>
      <Typography variant={'subtitle2'} align={'center'} sx={{ color: 'designGrey.500', mt: 3.75 }}>
        {t('info:web_no_longer')}
      </Typography>
      <Typography variant={'subtitle2'} align={'center'} sx={{ color: 'designGrey.500', mt: 3.75 }}>
        {t('info:download_install_app')}
      </Typography>
      <Box className={'horizontalFlex'} justifyContent={'center'} gap={2} mt={1.25}>
        <Box
          className={'verticalFlex'}
          justifyContent={'space-between'}
          height={106}
          onClick={close}
        >
          <Link href={GOOGLE_PLAY_LINK} target="_blank">
            <Box
              className={'box-flex-center'}
              sx={{ width: 46, height: 46, borderRadius: 3.5, border: '1px solid #EEF2F4' }}
              pl={0.75}
            >
              <CardMedia
                component="img"
                src={require('assets/images/google-square.png')}
                sx={{ width: 26, height: 'auto' }}
              />
            </Box>
          </Link>
          <Link href={APP_STORE_LINK} target="_blank">
            <Box className={'box-flex-center'} sx={{ width: 46, height: 46, borderRadius: 3.5, border: '1px solid #EEF2F4' }}>
              <CardMedia
                component="img"
                src={require('assets/images/ios-square.png')}
                sx={{ width: 26, height: 'auto' }}
              />
            </Box>
          </Link>
        </Box>
        <Box className={'box-flex-center'} sx={{ width: 106, height: 106, borderRadius: 3.5, border: '1px solid #EEF2F4' }}>
          <QRCode value={FIREBASE_DEEP_LINK} size={90} />
        </Box>
      </Box>
    </Box>
  )
}

export default (SoonNotEnabled)
