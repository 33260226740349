import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(({ zIndex, palette, breakpoints, spacing, isHebrew }) => ({
  toolbarRoot: {
    height: 56,
    backgroundColor: palette.orange.light,
    minHeight: 56,
    justifyContent: 'center',
    flexDirection: isHebrew ? 'row-reverse' : 'row',
    gap: spacing(2.5),
    [breakpoints.up(600)]: {
      minHeight: 56,
    },
  },
}))

export default useStyles
